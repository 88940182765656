
/** default functions used for compareWith atribute of an MatSelect */
export class SelectUtilities {

    static compareById(value, obj) {
        if (obj && value) {
            return (obj['id'] == value || value['id'] == obj['id']);
        }
        return false
    }

    static compareByDescription(obj1, obj2) {
        return obj1 && obj2 && obj1['description'] == obj2['description']
    }

    static compareByCode(obj1, obj2) {
        if (obj1['code']) {
            if (obj2['code']) {
                return obj1.code == obj2.code
            }
            return obj1.code == obj2
        }
        if (obj2['code']) {
            return obj2.code == obj1
        }
        return obj1 == obj2;
    }

    static compareValueWithCode(obj, value) {
        if (obj && obj['code'] && value) {
            return value.toString() == obj['code'].toString()
        }
        return false
    }

}
